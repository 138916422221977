<template>
  <div class="user-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "操作日志",
        search: [
          {
            key: "name",
            label: "搜索输入"
          },
          {
            key: "user",
            label: "操作账号"
          },
          {
            key: "type",
            label: "操作类型",
            type: "select",
            opt: {
              list: [
                {
                  label: "创建",
                  value: true
                },
                {
                  label: "test",
                  value: false
                }
              ]
            }
          }
        ],
        columns: [
          {
            label: "编号",
            key: "id",
            width: 120
          },
          { label: "操作时间",  key: "operationTime", sortable: true},
          { label: "操作账号", key: "user" },
          { label: "用户类型", key: "type"},
          { label: "租户姓名", key: "name" },
          { label: "操作者", key: "operation"},
          { label: "IP地址", key: "Ip"},
          { label: "操作类型", key: "operationType"},
          { label: "操作详情", key: "operationDetails"}
        ],
        buttons: [
        ]
      }
    };
  },
  created() {
    console.log("user-list created!!");
  },
  methods: {
    onGet(opt) {
      let dto = {
        skip: opt.skip,
        limit: opt.limit,
        ...opt.searchForm
      };
      this.post("/actionLog/list", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.operationTime = this.format(item.operationTime);
          item.type = item.type === 1 ? '系统用户' : '临时用户' ;
          item.operationType = item.operationType === 1 ? '创建' : '删除' ;
        }
        opt.cb(res.data);
      });
    }
  }
};
</script>